import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  Button,
  Slider,
  CircularProgress,
  ClickAwayListener,
  TextareaAutosize,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import get from 'lodash/get';
import pluralize from 'pluralize';

import {
  closeInventoryModal,
  openInventoryItemModal,
  updateInventory,
} from 'features/influencerSetupSlice';
import { formatPrice, normalizeNetworkName } from 'utils/helpers';
import { getRequest, postRequest, putRequest } from 'utils/api';
import { toggleInventoryActive } from 'features/profilesSlice';
import { openModal } from 'features/utilsSlice';
import CloseIcon from 'public/icons/close-main.svg';
import EditIcon from 'public/icons/edit-grey.svg';
import GreenCheckCircle from 'public/icons/green-check-circle.svg';
import InventoryItemModal from './InventoryItemModal';
import DropzoneField from 'components/gigSetup/DropzoneField';
import ActivityToggleButton from 'components/ActivityToggleButton';
import styles from 'styles/components/influencerSetup/InventoryModal.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const padding = { padding: '0 15px 25px' };
const noMargin = { marginBottom: 0 };
const flex = { display: 'flex', justifyContent: 'space-between' };
const buttonStyle = { padding: 0, margin: 0 };
const marginRight = { marginRight: 'auto' };
const negativeMarginRight = { marginRight: '-15px' };

const InventoryModal = ({ fetchInventories, profile }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editContent, setEditContent] = useState(false);
  const [description, setDescription] = useState('');
  const [liveTimeOptions, setLiveTimeOptions] = useState([]);
  const [edit, setEdit] = useState(false);
  const [selectedInventoryItem, setSelectedInventoryItem] = useState({});
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    inventoryModalOpen,
    currentInventory,
    currentInfluencerNetwork,
    currentInventorySpace,
  } = useSelector(state => state.influencerSetup);
  const inputRef = useRef();

  const spaceName = get(currentInventorySpace, 'name');
  const networkName = normalizeNetworkName(get(currentInfluencerNetwork, 'network.name'));

  const handleChange = (_, newValue) =>
    dispatch(updateInventory({ weekly_availability: newValue }));

  const title = () => `${networkName} ${spaceName}`;

  const inventoryItems = get(currentInventory, 'inventory_items', []);

  const handleSubmit = async () => {
    setIsLoading(true);

    const data = new FormData();

    const { inventory_items, weekly_availability, media, id } = currentInventory;

    data.set(
      'inventory[weekly_availability]',
      ['Digital Signature', 'Hand Signature'].includes(spaceName) ? 100 : weekly_availability
    );
    data.set('inventory[inventory_space_id]', currentInventorySpace.id);
    data.set('inventory[influencer_network_id]', currentInfluencerNetwork.id);

    if (media) {
      data.set('inventory[media]', media);
    }

    inventory_items.forEach(item => {
      if (item?.id) {
        data.append(`inventory[inventory_items_attributes][][id]`, item?.id);
      }

      if (item?._destroy) {
        data.append(`inventory[inventory_items_attributes][][_destroy]`, item?._destroy);
      }

      if (item?.min_offer_price && item?.max_offer_price) {
        data.append(
          `inventory[inventory_items_attributes][][min_offer_price]`,
          item?.min_offer_price
        );

        data.append(
          `inventory[inventory_items_attributes][][max_offer_price]`,
          item?.max_offer_price
        );
      }

      data.set('inventory[description]', description);

      data.append(`inventory[inventory_items_attributes][][price]`, item?.price);

      data.append(
        `inventory[inventory_items_attributes][][live_time_option_id]`,
        item?.live_time_option_id
      );

      data.append(`inventory[inventory_items_attributes][][average_views]`, item?.average_views);

      data.append(
        `inventory[inventory_items_attributes][][min_guaranteed_views]`,
        item?.min_guaranteed_views
      );
    });

    const request = id ? putRequest : postRequest;
    const endpoint = id ? `inventories/${id}` : 'inventories';

    request({ endpoint, data, config: { headers: { 'Content-Type': 'multipart/form-data' } } })
      .then(() => {
        dispatch(closeInventoryModal());
        fetchInventories();
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  const handleSaveDescription = () => {
    dispatch(updateInventory({ description }));
    setEditContent(false);
  };

  const isDisabled =
    inventoryItems.length === 0 ||
    inventoryItems.filter(i => !i['_destroy']).length === 0 ||
    (currentInventorySpace?.has_media
      ? !currentInventory?.media && !currentInventory?.media_url
      : false);

  useEffect(() => {
    if (!currentInventorySpace) return;

    getRequest({
      endpoint: 'live_time_options',
      query: { inventory_space_id: currentInventorySpace.id, per_page: 100 },
    })
      .then(options => {
        setLiveTimeOptions(options);
      })
      .catch(() => {});
  }, [currentInventorySpace]);

  const formatLiveTime = item => {
    if (currentInventorySpace?.name === 'Guaranteed Gains') {
      return `${item.live_time_duration} ${pluralize(
        item.live_time_unit,
        item.live_time_duration
      )}`;
    }

    if (currentInventorySpace?.offer) {
      return item.live_time_duration === 1 && item.live_time_unit === 'hour' ? 'Hourly' : 'Daily';
    }

    if (item.live_time_unit === 'none') return `1 ${currentInventorySpace?.name}`;

    return `${item.live_time_duration} ${pluralize(item.live_time_unit, item.live_time_duration)} ${
      item.live_time_unit !== 'time' ? 'live' : ''
    }`;
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={inventoryModalOpen}
        onClose={() => dispatch(closeInventoryModal())}
        TransitionComponent={Transition}
        className={styles.dialog}
      >
        <AppBar className={styles.appBar}>
          <Toolbar style={flex}>
            <div style={{ flex: 1 }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => dispatch(closeInventoryModal())}
                aria-label="close"
                style={buttonStyle}
              >
                <CloseIcon style={marginRight} width={15} height={15} />
              </IconButton>
            </div>

            <h3 className={styles.title}>{title()}</h3>
            {(!currentInventorySpace?.has_media && currentInventory?.id) ||
            (currentInventorySpace?.has_media &&
              !!currentInventory?.media_url &&
              currentInventory?.id) ? (
              <ActivityToggleButton
                active={currentInventory.active}
                resourceKey="inventory"
                endpoint={`inventories/${currentInventory.id}`}
                callback={({ active }) => {
                  dispatch(updateInventory({ active }));
                  dispatch(
                    toggleInventoryActive({
                      id: currentInventory.id,
                      username: profile.username_lower,
                      active,
                    })
                  );
                }}
              />
            ) : (
              <div style={{ flex: 1 }} />
            )}
          </Toolbar>
        </AppBar>

        <div className={styles.modalContent}>
          <div style={padding}>
            {!['Digital Signature', 'Hand Signature'].includes(spaceName) && (
              <>
                <ClickAwayListener onClickAway={() => setEditContent(false)}>
                  <div className={styles.content}>
                    {editContent ? (
                      <div className={styles.contentDescription}>
                        <TextareaAutosize
                          onChange={e => setDescription(e.target.value)}
                          defaultValue={
                            currentInventory?.description || currentInventorySpace?.description
                          }
                          autoFocus={editContent}
                          ref={inputRef}
                        />

                        <IconButton onClick={handleSaveDescription} style={negativeMarginRight}>
                          <GreenCheckCircle />
                        </IconButton>
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setEditContent(true);

                          setTimeout(() => {
                            inputRef.current.selectionStart = description.length;
                            inputRef.current.selectionEnd = description.length;
                          });
                        }}
                        className={styles.contentDescription}
                      >
                        <p>{currentInventory?.description || currentInventorySpace?.description}</p>

                        <EditIcon />
                      </div>
                    )}
                  </div>
                </ClickAwayListener>

                <div className="slider-title">
                  <h4 className={styles.label} style={noMargin}>
                    How many can you do per week?
                  </h4>

                  <span>{currentInventory.weekly_availability}</span>
                </div>

                <Slider
                  value={currentInventory.weekly_availability}
                  name="weekly_availability"
                  onChange={handleChange}
                  min={1}
                  max={100}
                  step={1}
                />
              </>
            )}
          </div>

          {currentInventorySpace?.has_media && (
            <>
              <div style={padding}>
                <h4 className={styles.label}>{currentInventorySpace.name}</h4>

                <div
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (!fullScreen) return;

                    dispatch(
                      openModal({ name: 'Signature Camera', data: { inventory: currentInventory } })
                    );
                  }}
                >
                  <DropzoneField
                    disabled={fullScreen}
                    setFile={file => dispatch(updateInventory({ media: file }))}
                    file={currentInventory.media}
                    accept="image/jpg, image/jpeg, image/png, image/heic, image/heif"
                    defaultPreview={currentInventory?.media_url || null}
                    updatePreview
                  />
                </div>

                <span>File types allowed: jpg, jpeg, png, heic</span>
              </div>
            </>
          )}

          <div>
            <div className={styles.addPrice}>
              <h4>Pricing</h4>

              {inventoryItems.length !== liveTimeOptions.length && (
                <Button onClick={() => dispatch(openInventoryItemModal())}>
                  <h5>+ ADD PRICE OPTION</h5>
                </Button>
              )}
            </div>

            {!currentInventory?.id && inventoryItems.length > 0 && (
              <p style={{ padding: '0px 25px 20px', marginTop: '-15px' }}>
                We have suggested some pricing options for you below to save some time. You can edit
                or remove them before saving. These prices can be adjusted at anytime in the future.
              </p>
            )}

            {inventoryItems.length > 0 ? (
              <div style={{ paddingBottom: 20 }} className={styles.inventoryItems}>
                {[...inventoryItems]
                  .sort((a, b) => b.default - a.default)
                  .map((item, index) =>
                    item['_destroy'] ? null : (
                      <div
                        key={index}
                        onClick={() => {
                          setEdit(true);
                          setSelectedInventoryItem(item);
                          dispatch(openInventoryItemModal({ ...item, index }));
                        }}
                        className={styles.priceOption}
                      >
                        <div className={styles.availability}>
                          <Image
                            src={currentInfluencerNetwork?.network?.active_icon_url}
                            width={30}
                            height={30}
                            alt="network"
                            unoptimized
                          />

                          <h4>{formatLiveTime(item)}</h4>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {item.default && <span className="green-chip">default</span>}
                          {!item.active && item.id && (
                            <span
                              className="disabled-chip"
                              style={{ marginLeft: item.default ? 3 : 0 }}
                            >
                              disabled
                            </span>
                          )}

                          <h4 className="color-main">
                            {currentInventorySpace?.offer
                              ? `${formatPrice(item.min_offer_price)} - ${formatPrice(
                                  item.max_offer_price
                                )}`
                              : formatPrice(Math.round(item.price))}
                          </h4>

                          <EditIcon />
                        </div>
                      </div>
                    )
                  )}
              </div>
            ) : (
              <p className={styles.description}>
                You can tap ‘+add price option’ above to add multiple price options for your{' '}
                {title()} based on different variables.
              </p>
            )}
          </div>
        </div>
        <div className={styles.buttons}>
          <Button style={{ display: 'none' }} onClick={() => dispatch(closeInventoryModal())}>
            Cancel
          </Button>

          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={isDisabled || isLoading}
          >
            {isLoading ? <CircularProgress color="primary" /> : 'Done'}
          </Button>
        </div>
      </Dialog>

      <InventoryItemModal
        edit={edit && currentInventory?.id}
        selectedInventoryItem={selectedInventoryItem}
      />
    </div>
  );
};

InventoryModal.propTypes = {
  fetchInventories: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

export default InventoryModal;
